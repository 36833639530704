import React from 'react'

export default function ChangePasswordPage(props) {
    return (
        <div className='login-box'>
            <div className='login-box-element'>
                <div>Current Password:</div>
                <input className='login-field' type='password' value={props.password} onChange={props.passwordChange}/>
            </div>
            <div className='login-box-element'>
                <div>New Password:</div>
                <input className='login-field' type='password' value={props.newPassword} onChange={props.newPasswordChange}/>
            </div>
            <div className='login-box-element'>
                <div>Confirm Password:</div>
                <input className='login-field' type='password' value={props.confirmPassword} onChange={props.confirmPasswordChange}/>
            </div>
            <div className='login-message'>{props.changePasswordMessage}</div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Submit' onClick={props.fetchChangePassword}/>
            </div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Cancel' onClick={props.closeChangePassword}/>
            </div>
        </div>
    )
}
