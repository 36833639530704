import React, { Component } from 'react'
import SettingsPage from './Components/SettingsPage/SettingsPage'
import ChangePasswordPage from './Components/ChangePasswordPage/ChangePasswordPage'
import SubmitFeedbackPage from './Components/SubmitFeedbackPage/SubmitFeedbackPage'
import fetchSubmitLogs from '../../Functions/FetchSubmitLogs'
import logFetchError from '../../Functions/LogFetchError'

export default class AccountSettings extends Component {
    constructor() {
        super()
        this.state = {
            settingsView: 'accountSettings',
            feedbackMessage: '',
            newPassword: '',
            confirmPassword: '',
            password: '',
            newPassword: '',
            confirmPassword: '',
            changePasswordMessage: '',
            feedbackContent: '',
            feedbackErrorMessage: '',
        }
    }

    fetchChangePassword() {
		if (this.state.newPassword !== this.state.confirmPassword) {
			this.setState({changePasswordMessage: 'New Password and Confirm Password must match'});
		}
		else {
			this.props.setFetchOutstanding(true)
			fetch(`${this.props.route}/changepassword.webapi`, {
				method: 'POST',
				headers: {
					'Token': this.props.userData.Token,
					'Content-Type': 'text/plain',
				},
				body: JSON.stringify({
					customerId: this.state.userData.CustomerId,
					oldPassword: this.state.password,
					newPassword: this.state.newPassword,
				})
			})
			.then(response => {
				this.props.setFetchOutstanding(false)
				if (response.status === 401) {
                    this.props.setAuthenticationError(true)
					throw 'Authentication Error'
				} else {
					return response.text()
				}
			})
			.then(response => {
				let result = response

				//Attempt sending logs
				fetchSubmitLogs(null, null)

				if (result.includes('Password updated')) {
					this.setState({
						password: '',
						newPassword: '',
						confirmPassword: '',
					});
				}
				result = result.replace('{', '');
				result = result.replace('}', '');
				this.setState({changePasswordMessage: result});
			})
			.catch(error => {
				this.props.setFetchOutstanding(false)
				// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
				// 	this.setState({authenticationError: true})
				// }
				console.log('error', error);
				logFetchError(error, this.props.userProfile, this.props.userData, 'fetchChangePassword')
			});
		}
    }
    
    submitFeedbackHandler = () => {
        this.props.setFetchOutstanding(true)
		fetch(`${this.props.route}/feedback.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.props.userData.CustomerId,
				feedback: this.state.feedbackContent
			})
		})
		.then((response) => {
			this.props.setFetchOutstanding(false)
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw 'Authentication Error'
			} else {
				const decoder = new TextDecoder('utf-8')
				let result = ''
				let reader = response.body.getReader()
				reader
					.read()
					.then(function processText({ done, value }) {
						if (done) {
							return result
						}
					
						result += decoder.decode(value);
					
						return reader.read().then(processText);
					})
					.then((response) => {
						this.setState({feedbackMessage: response, applicationView: 'accountSettings', feedbackContent: '', feedbackErrorMessage: ''})
					})
			}
		})
		.catch((error) => {
			this.props.setFetchOutstanding(false)
			if (typeof error === 'string') {
				this.setState({feedbackErrorMessage: error})
			} else {
				this.setState({feedbackErrorMessage: 'Error Submitting Feedback'})
			}
			logFetchError(error, this.props.userProfile, this.props.userData, 'submitFeedbackHandler')
		})
    }

    render() {
        return (
            <div className={`login-screen ${!this.props.production && 'login-screen-test-background'}`}>
                {this.state.settingsView === 'accountSettings' ?
                    <SettingsPage
                        showChangePassword={() => this.setState({settingsView: 'changePassword'})}
                        showSubmitFeedback={() => this.setState({settingsView: 'submitFeedback'})}
                        userProfile={this.props.userProfile}
                        updateSelectedTimeZone={this.props.updateSelectedTimeZone}
                        showLoading={this.props.showLoading}
                        showLoadingHandler={this.props.showLoadingHandler}
                        closeSettings={this.props.closeSettings}
                        feedbackMessage={this.state.feedbackMessage}
                    />
                : this.state.settingsView === 'changePassword' ?
                    <ChangePasswordPage
                        closeChangePassword={() => this.setState({settingsView: 'accountSettings'})}
                        password={this.state.password}
                        passwordChange={(e) => this.setState({password: e.target.value})}
                        newPassword={this.state.newPassword}
                        newPasswordChange={(e) => this.setState({newPassword: e.target.value})}
                        confirmPassword={this.state.confirmPassword}
                        confirmPasswordChange={(e) => this.setState({confirmPassword: e.target.value})}
						changePasswordMessage={this.state.changePasswordMessage}
						fetchChangePassword={this.fetchChangePassword}
                    />
                : this.state.settingsView === 'submitFeedback' &&
                    <SubmitFeedbackPage
                        feedbackContent={this.state.feedbackContent}
                        updateFeedbackContent={(e) => this.setState({feedbackContent: e.target.value})}
                        submitFeedbackHandler={this.submitFeedbackHandler}
                        closeFeedbackHandler={() => this.setState({settingsView: 'accountSettings', feedbackErrorMessage: ''})}
                        feedbackErrorMessage={this.state.feedbackErrorMessage}
                    />
                }
            </div>
        )
    }
}
