import React from 'react';
import '../../CaseList.css';
import logFetchError from '../../../../Functions/LogFetchError'
import fetchSubmitLogs from '../../../../Functions/FetchSubmitLogs'

class  CaseSelect extends React.Component {

	constructor(props) {
		super(props);
		
		this.fetchReviewLater = this.fetchReviewLater.bind(this);

		this.selectedCase = React.createRef();// ref used for auto scrolling cases into view
	}

	/*
	reviewlater api call
	*/
	fetchReviewLater() {
		this.props.setFetchOutstanding(true)
		fetch(`${this.props.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: this.props.data.ID,
				marked: !this.props.data.ReviewLaterFlag
			})
		})
		.then(response => {
			this.props.setFetchOutstanding(false)
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {
			this.props.reviewLaterToggle(this.props.data.ID);
		})
		.catch(error => {
			this.props.setFetchOutstanding(false)
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchReviewLater')
		});
	}

	render() {

		let titleColumn;
		let diagnosisColumn;
		let yieldColumn;
		
		if (this.props.title) {
			titleColumn = <div className='column title'>{this.props.data.TITLE}</div>
		}

		if (this.props.diagnosis) {
			diagnosisColumn = <div className='column diagnosis'>{this.props.data.DIAGNOSIS}</div>
		}

		if (this.props.yield) {
			yieldColumn = <div className='column yield'>{this.props.data.HIGHYIELDCOUNT}</div>
		}

		// highlight the selected case
		let style;
		if (this.props.selectedCase === this.props.data.ID) {
			style = 'table-row selected';
			if (this.props.scroll) {
				this.selectedCase.current.scrollIntoView();
			}
		}
		else {
			style = 'table-row';
		}

		let completed;
		if (this.props.data.LastCompletionDate === '0001-01-01T00:00:00') {
			completed = 'Incomplete';
		}
		else {
			completed = new Date(`${this.props.data.LastCompletionDate}Z`);
			completed = completed.toLocaleDateString();
		}

		return(
			<div className={style} onClick={this.props.caseSelectClick.bind(this, this.props.data.ID, false)} ref={this.selectedCase}>
				<div className='column id'>{this.props.data.ID}</div>
				<div className='wrapper title-category-diagnosis-time'>
					{titleColumn}
					<div className='column category'>{this.props.data.CASECATEGORYDESC}</div>
					{diagnosisColumn}
					{/* <div className='column time'>{this.props.data.TIMEMODEDESC}</div> */}
				</div>
				<div className='wrapper completed-review-grade'>
					<div className='column completed'>{completed}</div>
					{yieldColumn}
					<div className='column review'>
						<input type='checkbox' checked={this.props.data.ReviewLaterFlag} onChange={this.fetchReviewLater}/>
					</div>
					<div className='column grade'>{Math.round(this.props.data.AvgGradeForCase) + '%'}</div>
				</div>
			</div>
		);
	}
}

export default CaseSelect;
