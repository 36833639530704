import React from 'react'

export default function SubmitFeedbackPage(props) {
    return (
        <div className='login-box'>
            <div className='login-box-element'>
                <div>Describe your issue</div>
                <textarea className='feedbackTextarea' type='text' value={props.feedbackContent} onChange={props.updateFeedbackContent}/>
            </div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Submit' onClick={props.submitFeedbackHandler}/>
            </div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Cancel' onClick={props.closeFeedbackHandler}/>
            </div>
            <div className='login-message'>{props.feedbackErrorMessage}</div>
        </div>
    )
}
