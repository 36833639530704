import React from 'react'

export default function SettingsPage(props) {

    const logout = () => {
		window.location.reload();
    }
    
    return (
        <div className='login-box'>
            <p className='account-info-text'>First Name: {props.userProfile.firstName}</p>
            <p className='account-info-text'>Last Name: {props.userProfile.lastName}</p>
            <p className='account-info-text'>Username: {props.userProfile.userName}</p>
            <p className='account-info-text' style={{color: 'red'}}>Subscription End Date: {props.userProfile.subscriptionEndDate}</p>
            <p className='account-info-text'>Account Creation Date: {props.userProfile.accountCreationDate}</p>
            <p className='account-info-text'>Demo: {props.userProfile.demo ? 'Yes' : 'No'}</p>
            <p className='account-info-text'>Case Completion Limit: {props.userProfile.caseCompletionLimit ? 'Yes' : 'No'}</p>
            <label for='timezoneDropdown' className='account-info-text'>Time Zone</label>
            <select name='timezoneDropdown' id='timezoneDropdown' value={props.userProfile.selectedTimeZone} onChange={props.updateSelectedTimeZone}>
                <option value="-12">GMT -12 US Minor Outlying Islands</option>
                <option value="-11">GMT -11 US Minor Outlying Islands</option>
                <option value="-10">GMT -10 Honolulu</option>
                <option value="-9">GMT -9 Alaska Time</option>
                <option value="-8">GMT -8 Pacific Time</option>
                <option value="-7">GMT -7 Mountain Time</option>
                <option value="-6">GMT -6 Central Time</option>
                <option value="-5">GMT -5 Eastern Time</option>
                <option value="-4">GMT -4 Atlantic Time</option>
                <option value="-3">GMT -3 Greenland</option>
                <option value="-2">GMT -2 Brazil</option>
                <option value="-1">GMT -1 Portugal</option>
                <option value="+0">GMT +0 United Kingdom</option>
                <option value="+1">GMT +1 Paris</option>
                <option value="+2">GMT +2 Johannesburg</option>
                <option value="+3">GMT +3 Moscow</option>
                <option value="+4">GMT +4 Dubai</option>
                <option value="+5">GMT +5 Karachi</option>
                <option value="+6">GMT +6 Dhaka</option>
                <option value="+7">GMT +7 Jakarta</option>
                <option value="+8">GMT +8 Shanghai</option>
                <option value="+9">GMT +9 Tokyo</option>
                <option value="+10">GMT +10 Sydney</option>
                <option value="+11">GMT +11 Nouméa</option>
                <option value="+12">GMT +12 Auckland</option>
                <option value="+13">GMT +13 Tonga</option>
                <option value="+14">GMT +14 Kiribati</option>
            </select>
            <div className='account-info-show-loader-container' style={{paddingBottom: '20px', borderBottom: '1px solid rgb(220, 220, 220'}}>
                <p className='account-info-text'>Show loading popup: </p>
                <input type='checkbox' className='account-info-checkbox' checked={props.showLoading} onClick={props.showLoadingHandler}/>
            </div>
            <br />
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Change Password' onClick={props.showChangePassword}/>
            </div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Report a Bug / Feedback' onClick={props.showSubmitFeedback}/>
            </div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Logout' onClick={() => logout()}/>
            </div>
            <div className='login-box-element bottom'>
                <input className='login-button' type='button' value='Return to Case Selection' onClick={props.closeSettings}/>
            </div>
            <div className='login-success-message'>{props.feedbackMessage}</div>
        </div>
    )
}
