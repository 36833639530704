import React from 'react'
import './CaseList.css'
import CaseSelect from './Components/CaseSelect/CaseSelect'
import IncompleteCasePopup from './Components/IncompleteCasePopup/IncompleteCasePopup'
import logFetchError from '../../Functions/LogFetchError'
import fetchSubmitLogs from '../../Functions/FetchSubmitLogs'

class CaseList extends React.Component {
	
	constructor(props) {
		super(props);

		let selectedCase = 1;
		if (this.props.selectedCase) {
			selectedCase = this.props.selectedCase;
		}
		
		this.state = {
			selectedCase: selectedCase,			// default selected case
			yield: false,				// show high yield count column, hard coded toggle
			displayIncompleteCases: false,
			incompleteCaseList: [],
			selectedIncompleteCase: 0,
			startNewCaseFunction: null
		}

		this.viewCaseGradesClick = this.viewCaseGradesClick.bind(this);
		this.fetchCaseList = this.fetchCaseList.bind(this);
		this.caseSelectClick = this.caseSelectClick.bind(this);
		this.selectRandomClick = this.selectRandomClick.bind(this);
		this.selectRandomIncompleteClick = this.selectRandomIncompleteClick.bind(this);
		this.runCaseClick = this.runCaseClick.bind(this);
		this.sortTableClick = this.sortTableClick.bind(this);
		this.reviewLaterToggle = this.reviewLaterToggle.bind(this);
	}
	
	/*
	automatically make listcases api call
	*/
	componentDidMount() {
		this.fetchCaseList().then(() => {
			if (!(this.props.caseListSort === 'id' && this.props.caseListReversed === false)) {
				this.sortTableClick(this.props.caseListSort, true)
			}
			let table = document.querySelector('#caseListTable')
			table.scrollTop = this.props.scrollPosition
		})
	}

	viewCaseGradesClick() {
		// only view case grades if selected case has been completed
		if (!this.state.incompleteCases.includes(this.state.selectedCase)) {

			// TODO get review later flag for selectedCase
			let reviewLater;
			let diagnosis;
			for (let i = 0; i < this.state.caseListJson.length; i++) {
				if (this.state.caseListJson[i].ID === this.state.selectedCase) {
					reviewLater = this.state.caseListJson[i].ReviewLaterFlag;
					diagnosis = this.state.caseListJson[i].DIAGNOSIS
				}
			}

			this.props.viewCaseGrades(this.state.selectedCase, reviewLater, diagnosis);
		}
	}

	/*
	listcases api call
	*/
	async fetchCaseList() {
		this.props.setFetchOutstanding(true)
		await fetch(`${this.props.route}/listcases.webapi`, {
			method: 'GET',
			headers: {
				'Token': this.props.userData.Token,
			},
		})
		.then(response => {
			this.props.setFetchOutstanding(false)
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {
			
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			let result = response
			let results = JSON.parse(result)

			if (Array.isArray(results)) {
				this.setState({caseListJson: results});
			} else {
				this.props.updateAccountDetails(results)
				this.setState({caseListJson: results.lc})
			}
			// create array of incomplete cases for select random incomplete case
			let incompleteCases = [];
			for (let i = 0; i < this.state.caseListJson.length; i++) {
				if (this.state.caseListJson[i].LastCompletionDate === '0001-01-01T00:00:00') {
					incompleteCases.push(this.state.caseListJson[i].ID);
				}
			}
			this.setState({incompleteCases: incompleteCases});
		})
		.catch(error => {
			this.props.setFetchOutstanding(false)
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchCaseList')
		});
	}

	/*
	toggle the Review Later column checkbox
	searches through array by id to toggle review later
	*/
	reviewLaterToggle(id) {
		let tempJson = this.state.caseListJson;
		for (let i = 0; i < tempJson.length; i++) {
			if (tempJson[i].ID === id) {
				tempJson[i].ReviewLaterFlag = !tempJson[i].ReviewLaterFlag;
			}
		}
		this.setState({caseListJson: tempJson});
	}
	
	/*
	randomly generate a case id to select
	*/
	selectRandomClick() {
		this.caseSelectClick(Math.floor(Math.random() * Math.floor(this.state.caseListJson.length)) + 1, true);
	}
	
	/*
	randomly select a case from the incomplete cases array
	*/
	selectRandomIncompleteClick() {
		this.caseSelectClick(this.state.incompleteCases[Math.floor(Math.random()*this.state.incompleteCases.length)], true);
	}
	
	/*
	run the case simulation
	passes the caseData Json up to App
	*/
	runCaseClick() {
		if (this.state.selectedCase) {
			for (let i = 0; i < this.state.caseListJson.length; i++) {
				if (this.state.caseListJson[i].ID === this.state.selectedCase) {
					let table = document.querySelector('#caseListTable')
					this.props.setCurrentScrollPosition(table.scrollTop)
					this.props.fetchStartCase(this.state.caseListJson[i], this.incompleteStartDisplayHandler);
				}
			}
		}
	}

	incompleteStartDisplayHandler = (ialist, callBack) => {
		this.setState({displayIncompleteCases: true, startNewCaseFunction: callBack, incompleteCaseList: ialist})
	}

	/*
	changes the selected case
	this is the case that is highlighted and will be run when run case is clicked
	scroll determines whether the case should be scrolled into view or not
	scroll is true when randomly selecting a case but false when clicking on a case
	*/
	caseSelectClick(id, scroll) {
		// scroll case into view when selected randomly
		this.setState({
			selectedCase: id,
			scroll: scroll
		});
	}

	/*
	sort cases in table by column
	every column uses the same sorting algorithm except for review
	*/
	sortTableClick(column, mounting = false) {
		let tempArray = this.state.caseListJson

		if (!this.state.isCaseListReversed && this.state.caseListSort === column) {
			this.props.changeCaseListSort(column, true)
			return this.setState({caseListJson: tempArray.reverse(), isCaseListReversed: true})
		}

		tempArray.sort((a, b) => a.ID - b.ID)

		switch(column) {
			case 'review':
				tempArray.sort((a, b) => b.ReviewLaterFlag - a.ReviewLaterFlag)
				break;
			case 'title':
				tempArray.sort((a, b) => {
					if(a.TITLE < b.TITLE) { return -1; }
					if(a.TITLE > b.TITLE) { return 1; }
					return 0;
				})
				break;
			case 'category':
				tempArray.sort((a, b) => {
					if(a.CASECATEGORYDESC < b.CASECATEGORYDESC) { return -1; }
					if(a.CASECATEGORYDESC > b.CASECATEGORYDESC) { return 1; }
					return 0;
				})
				break;
			case 'diagnosis':
				tempArray.sort((a, b) => {
					if(a.DIAGNOSIS < b.DIAGNOSIS) { return -1; }
					if(a.DIAGNOSIS > b.DIAGNOSIS) { return 1; }
					return 0;
				})
				break;
			case 'time':
				tempArray.sort((a, b) => {
					if(a.TIMEMODEDESC < b.TIMEMODEDESC) { return -1; }
					if(a.TIMEMODEDESC > b.TIMEMODEDESC) { return 1; }
					return 0;
				})
				break;
			case 'completed':
				tempArray.sort((a, b) => new Date(b.LastCompletionDate) - new Date(a.LastCompletionDate))
				break;
			case 'yield':
				tempArray.sort((a, b) => b.HIGHYIELDCOUNT - a.HIGHYIELDCOUNT)
				break;
			case 'grade':
				tempArray.sort((a, b) => b.AvgGradeForCase - a.AvgGradeForCase)
				break;
			default:
				break;
		}

		if (mounting === true && this.props.caseListReversed) {
			tempArray.reverse()
		}

		this.props.changeCaseListSort(column, false)
		this.setState({caseListJson: tempArray, caseListSort: column, isCaseListReversed: false});

		// if (column === 'review') {
		// 	if (this.state.lastSort === 'review') {
		// 		tempArray = tempArray.reverse();
		// 		this.setState({lastSort: false});
		// 	}
		// 	else {
		// 		for (let i = 1; i < tempArray.length; i++) {
		// 			if (tempArray[i].ReviewLaterFlag) {
		// 				tempArray.unshift(tempArray.splice(i, 1)[0]);
		// 			} 
		// 		}
		// 		this.setState({lastSort: 'review'});
		// 	}
		// }
		// else {
		// 	let alreadySorted = true;// track if the array is already sorted
		// 	let sorted = false;// track when the algorithm is finished
		// 	while (!sorted) {
		// 		sorted = true;
		// 		for (let i = 1; i < tempArray.length; i++) {
					
		// 			// if not sorted by column
		// 			if (
		// 				(column === 'id' && tempArray[i-1].ID > tempArray[i].ID) ||
		// 				(column === 'title' && tempArray[i-1].TITLE.charAt(0) > tempArray[i].TITLE.charAt(0)) ||
		// 				(column === 'category' && tempArray[i-1].CASECATEGORYDESC.charAt(0) > tempArray[i].CASECATEGORYDESC.charAt(0)) ||
		// 				(column === 'diagnosis' && tempArray[i-1].DIAGNOSIS.charAt(0) > tempArray[i].DIAGNOSIS.charAt(0)) ||
		// 				(column === 'time' && tempArray[i-1].TIMEMODEDESC.charAt(0) > tempArray[i].TIMEMODEDESC.charAt(0)) ||
		// 				(column === 'completed' && Date.parse(tempArray[i-1].LastCompletionDate) < Date.parse(tempArray[i].LastCompletionDate)) ||
		// 				(column === 'yield' && tempArray[i-1].HIGHYIELDCOUNT > tempArray[i].HIGHYIELDCOUNT) ||
		// 				(column === 'grade' && tempArray[i-1].AvgGradeForCase > tempArray[i].AvgGradeForCase)
		// 			) {

		// 				// if out of order put in order
		// 				sorted = false;
		// 				alreadySorted = false;
		// 				//switch
		// 				let temp = tempArray[i-1];
		// 				tempArray[i-1] = tempArray[i];
		// 				tempArray[i] = temp;
		// 			} 
		// 		}
		// 	}
		
		// 	if (alreadySorted) {
		// 		tempArray = tempArray.reverse();
		// 	}
		// }

		// this.setState({caseListJson: tempArray});
	}

	render() {

		let yieldColumn;
		let titleColumn;
		let diagnosisColumn;

		if (this.state.yield) {
			yieldColumn = <div className='column yield' onClick={this.sortTableClick.bind(this, 'yield')}>High Yield Count</div>
		}

		if (this.props.showCaseTitle) {
			titleColumn = <div className='column title' onClick={this.sortTableClick.bind(this, 'title')}>Title</div>;
		}

		if (this.props.showCaseDiagnosis) {
			diagnosisColumn = <div className='column diagnosis' onClick={this.sortTableClick.bind(this, 'diagnosis')}>Diagnosis</div>;
		}

		let caseList;
		if (this.state.caseListJson !== undefined) {
			caseList = this.state.caseListJson.map((json) =>
				<CaseSelect 
					data={json} 
					title={this.props.showCaseTitle} 
					diagnosis={this.props.showCaseDiagnosis} 
					yield={this.state.yield}
					key={json.ID}
					selectedCase={this.state.selectedCase}
					caseSelectClick={this.caseSelectClick}
					setAuthenticationError={this.props.setAuthenticationError}
					scroll={this.state.scroll}
					userData={this.props.userData}
					userProfile={this.props.userProfile}
					reviewLaterToggle={this.reviewLaterToggle}
					route={this.props.route}
					setFetchOutstanding={this.props.setFetchOutstanding}
				/>
			);
		}

		let viewGradesStyle;
		if (this.state.incompleteCases && this.state.incompleteCases.includes(this.state.selectedCase)) {
			viewGradesStyle = 'fade';
		}

		return (
			<div className='case-list'>
				{this.state.displayIncompleteCases &&
					<IncompleteCasePopup
						userProfile={this.props.userProfile}
						selectedIncompleteCase={this.state.selectedIncompleteCase}
						startNewCaseFunction={this.state.startNewCaseFunction}
						closeIncompleteCases={() => this.setState({displayIncompleteCases: false})}
						incompleteCaseList={this.state.incompleteCaseList}
						changeSelectedCase={(newIndex) => this.setState({selectedIncompleteCase: newIndex})}
						confirmIncompleteCaseStart={this.props.confirmIncompleteCaseStart}
					/>
				}
				<div className='page-header'>
					<div className='page-title'>CDM Case Selection</div>
					<p className='page-error'>{this.props.caseListHeaderError}</p>
					<div><input type='button' value='Account Settings' onClick={this.props.accountSettingsClick}/></div>
				</div>
				<div className='table' style={{display: 'grid', gridTemplateRows: 'auto 1fr'}}>
					<div className='table-row header' style={{height: 'auto', minHeight: '21px'}}>
						<div className='column id' onClick={this.sortTableClick.bind(this, 'id')}>Id</div>
						<div className='wrapper title-category-diagnosis-time'>
							{titleColumn}
							<div className='column category' onClick={this.sortTableClick.bind(this, 'category')}>Category</div>
							{diagnosisColumn}
							{/* <div className='column time' onClick={this.sortTableClick.bind(this, 'time')}>Time Limit</div> */}
						</div>
						<div className='wrapper completed-review-grade'>
							<div className='column completed' onClick={this.sortTableClick.bind(this, 'completed')}>Completed</div>
							{yieldColumn}
							<div className='column review' onClick={this.sortTableClick.bind(this, 'review')}>Review Later</div>
							<div className='column grade' onClick={this.sortTableClick.bind(this, 'grade')}>Average Grade</div>
						</div>
						{/* <div className='spacer'></div> */}
					</div>
					<div className='table-content' id='caseListTable' style={{height: '100%'}}>
						{caseList}
					</div>
				</div>
				<div className='table-options'>
					<div onClick={this.props.togglePracticeMode}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.practiceMode}/>
						<label>Practice mode</label>
					</div>
					<div onClick={this.props.toggleShowCaseTitle}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.showCaseTitle}/>
						<label>Show Case Title</label>
					</div>
					<div onClick={this.props.toggleShowCaseDiagnosis}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.showCaseDiagnosis}/>
						<label>Show Case Diagnosis</label>
					</div>
				</div>
				<div className='options'>
					<div className='simulation-menu-options'>
						<div onClick={this.props.timedExamToggle}>
							<input type='checkbox' checked={this.props.timedExam}/>
							<label>Timed Exam</label>
						</div>
					</div>
					<div className='buttons'>
						<div className='program-options'>
							<input className='button' type='button' value='Select Random Case' onClick={this.selectRandomClick}/>
							<input className='button' type='button' value='Select Random Incomplete Case' onClick={this.selectRandomIncompleteClick}/>
						</div>
						<div className='program-options'>
							<input className={'button ' + viewGradesStyle} type='button' value='View Case Grades' onClick={this.viewCaseGradesClick}/>
							<input className='button' type='button' value='Run Case' onClick={this.runCaseClick}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CaseList;
