import React from 'react'
import './IncompleteCasePopup.css'

export default function IncompleteCasePopup(props) {

    const getFormattedDateHandler = (date) => {
        let formattedDate = new Date(`${date}Z`)
        let month = formattedDate.getUTCMonth() + 1
        let day = formattedDate.getUTCDate()
        let year = formattedDate.getUTCFullYear()
        return `${month}/${day}/${year}`
    }

    const getFormattedTimeHandler = (date) => {
        let formattedDate = new Date(`${date}Z`)
        let hours = formattedDate.getUTCHours()
        let minutes = formattedDate.getUTCMinutes()
        let dayPeriod = ''

        if (hours === 0) {
            hours = 12
            dayPeriod = 'AM'
        } else if (hours < 12) {
            dayPeriod = 'AM'
        } else if (hours === 12) {
            dayPeriod = 'PM'
        } else {
            hours -= 12
            dayPeriod = 'PM'
        }

        return `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${dayPeriod}`
    }

    const formatPreviousAttemptTimes = () => {
        let incompleteCaseList = JSON.parse(JSON.stringify(props.incompleteCaseList))
        if (props.userProfile.selectedTimeZone !== '-7') {
            let hoursAdjustment = Number(props.userProfile.selectedTimeZone) + 7
            incompleteCaseList.forEach((value, index, initialArray) => {
                let date = new Date(value.TimeAttempted)
                date.setTime(date.getTime() + (hoursAdjustment*60*60*1000))
                initialArray[index].TimeAttempted = date
            })
        }
        return incompleteCaseList
    }

    return (
        <div className='incompleteCasePopupPageWrapper'>
			<div className='incompleteCasePopupBackdrop' onClick={props.closeIncompleteCases}/>
            <div className='incompleteCasePopupContainer'>
                <div className='incompleteCasePopupHeaderContainer'>
                    <p className='incompleteCasePopupHeaderText'>Continue Previous Attempt?</p>
                    <button className='incompleteCaseCloseButton' onClick={props.closeIncompleteCases} />
                </div>
                <div className='incompleteCasePopupBodyContainer'>
                    {formatPreviousAttemptTimes().map((value, index) => (
                        <div className={`incompleteCaseContainer ${props.selectedIncompleteCase === index && 'selectedIncompleteCaseContainer'}`} key={index} onClick={() => props.changeSelectedCase(index)}>
                            <p className='incompleteCaseDate'>Date: {getFormattedDateHandler(value.TimeAttempted)}</p>
                            <p className='incompleteCaseTime'>Time: {getFormattedTimeHandler(value.TimeAttempted)}</p>
                        </div>
                    ))}
                </div>
                <div className='incompleteCasePopupFooterContainer'>
                    <button className='simulation-button incompleteCaseButton' onClick={props.startNewCaseFunction}>Start New Case</button>
                    <button className='simulation-button incompleteCaseButton' onClick={() => props.confirmIncompleteCaseStart(props.selectedIncompleteCase, props.incompleteCaseList)}>Continue Case</button>
                </div>
            </div>
        </div>
    )
}
