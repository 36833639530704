import React, { Component } from 'react'
import './App.css';
import AccountSettings from './Components/AccountSettings/AccountSettings';
import CaseList from './Components/CaseList/CaseList';
import Login from './Components/Login/Login';
import Simulation from './Components/Simulation/Simulation';
import logFetchError from './Functions/LogFetchError'
import fetchSubmitLogs from './Functions/FetchSubmitLogs'
import Spinner from 'react-md-spinner'
import Grading from './Components/Grading/Grading'
import UnauthenticatedHandler from './Components/UnauthenticatedHandler/UnauthenticatedHandler'

class App extends Component {
	constructor() {
		super()
		this.state = {
			authenticationError: false,
			userData: {},
			userProfile: {},
			timedExam: true,
			customTimeLimit: 'none',
			networkLag: 'none',
			caseGrades: null,
			caseListHeaderError: '',
			caseListScrollPosition: 0,
			route: '',
			fetchOutstanding: false,
			production: true,
			loginError: '',
			appDisplay: 'login',
			showLoading: false,
			startCaseData: {},
			caseData: {},
			simulationData: {},
			selectedCaseId: 0,
			practiceMode: false,
			grading: null,
			selectedCaseReviewLater: null,
			caseListSort: 'id',
			caseListReversed: false,
			showCaseTitle: false,
			showCaseDiagnosis: false,
		}
	}

	componentDidMount() {
		this.getRoute()
	}

	getRoute = () => {
		let route = ''
		if (process.env.REACT_APP_PRODUCTION !== 'false') {
			route = 'https://app.ccdmcases.com'
			this.setState({production: true})
		} else {
			route = `http://localhost:${process.env.REACT_APP_NETWORK_PORT ? process.env.REACT_APP_NETWORK_PORT : 80}`
			this.setState({production: false})
		}
		this.setState({route})
	}

  	formatTimeHandler = (date) => {
		let formattedDate = new Date(date)
		let hours = formattedDate.getUTCHours()
		let minutes = formattedDate.getUTCMinutes()
		let timeString = `${(hours === 0 || hours === 12) ? '12' : hours > 12 ? hours - 12 : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`
		return timeString
	}

  	formatDateHandler = (date) => {
		let formattedDate = new Date(date)
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		let dateString = `${months[formattedDate.getUTCMonth()]} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}, ${this.formatTimeHandler(date)}`
		return dateString
	}
  
	updateAccountDetails = (user) => {
		let userProfile = {
			firstName: user.CustomerFirstName,
			lastName: user.CustomerLastName,
			userName: user.CustomerUserName,
			subscriptionEndDate: this.formatDateHandler(`${user.LicenseExpiration}Z`),
			accountCreationDate: this.formatDateHandler(`${user.DateCustCreated}Z`),
			demo: user.Demo,
			caseCompletionLimit: user.LimitFiveCases,
			selectedTimeZone: user.TimeZone === "" ? "-7" : user.TimeZone
		}
		//Current default offset is -6
		this.setState({userProfile, showLoading: user.LoadingPopUp ? true : false})
	}

  	updateSelectedTimeZone = (event) => {
    	let initialTimeZone = this.state.userProfile.selectedTimeZone
		let userProfile = this.state.userProfile
		userProfile.selectedTimeZone = event.target.value
		this.setState({userProfile, fetchOutstanding: true})
		fetch(`${this.state.route}/updatetimezone.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				timeZone: event.target.value
			})
		})
		.then((response) => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			}
		})
		.catch((error) => {
			let userProfile = this.state.userProfile
			userProfile.selectedTimeZone = initialTimeZone
			this.setState({userProfile, fetchOutstanding: false})
			logFetchError(error, this.state.userProfile, this.state.userData, 'updateSelectedTimeZone')
		})
  	}

  	showLoadingHandler = () => {
		let newValue = !this.state.showLoading
		this.setState({fetchOutstanding: true, showLoading: newValue})
		fetch(`${this.state.route}/loadingpopup.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				LoadingPopUp: newValue
			})
		})
		.then((response) => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			}
		})
		.catch((error) => {
			this.setState({fetchOutstanding: false, showLoading: !newValue})
			logFetchError(error, this.state.userProfile, this.state.userData, 'showLoadingHandler')
		})
  	}

  	getAnswerList = (simulationData) => {
		let answerList = []
		simulationData.qo.forEach((value, index) => {
			if (value.BLANKTYPE) {
				let inputAnswerInfo = []
				for(let i = 0; i < value.MAXACCEPTEDANSWERS; ++i) {
					inputAnswerInfo.push('')
				}
				answerList.push(inputAnswerInfo)
			} else if (value.MCTYPE) {
				let multipleChoiceAnswerInfo = []
				simulationData.ao.forEach((answerOutput, answerIndex) => {
					if (answerOutput.QUESTIONID === value.QUESTIONID) {
						multipleChoiceAnswerInfo.push({
							option: answerOutput.ANSWER,
							selected: false
						})
					}
				})
				answerList.push(multipleChoiceAnswerInfo)
			}
		})
		return answerList
  	}
  
  	fetchStartCase = (caseData, openIncompleteCaseWindow) => {
	  	this.setState({selectedCaseId: caseData.ID, fetchOutstanding: true, selectedCaseReviewLater: caseData.ReviewLaterFlag})
		fetch(`${this.state.route}/startcase.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				caseId: caseData.ID
			})
		})
		.then(response => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {

			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			let result = response
			if (result.includes('Error: ')) {
				this.setState({caseListHeaderError: result})
			} else {
				let simulationData = JSON.parse(result);
				this.setState({caseguid: simulationData.caseguid})

				simulationData.timeRemainingInSeconds = simulationData.TOTALMINFORCASE * 60

				simulationData.INITIALINFO = simulationData.INITIALINFO.split(',')

				let newInfo = []

				// Find degrees text and replace it with character, then split string into two seperate values and add them
				// to newInfo as an object
				simulationData.INITIALINFO.forEach((value, index) => {
					let tempValue = value
					if (tempValue.includes(' degrees ')) {
						tempValue = tempValue.replace(' degrees ', '°')
					}
					let tempArray = tempValue.split(': ')
					newInfo.push({
						vital: tempArray[0],
						value: tempArray[1]
					})
				})
				simulationData.INITIALINFO = [...newInfo]

				simulationData.answerList = this.getAnswerList(simulationData)

				simulationData.currentQuestion = 1
				if (simulationData.ialist.length === 0) {
					this.setState({
						caseData: caseData,
						caseListHeaderError: '',
						simulationData: simulationData,
						appDisplay: 'simulation'
					}, () => {
						this.setState({})
					})
				} else {
          			let incompleteCaseList = [...simulationData.ialist]
					this.setState({
						caseData: caseData,
						caseListHeaderError: '',
						simulationData: simulationData
					})
					openIncompleteCaseWindow(incompleteCaseList.reverse(), () => {
						this.setState({appDisplay: 'simulation'})
					})
				}
			}
		})
		.catch(error => {
			this.setState({fetchOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.setState({authenticationError: true})
			// }
			console.log('error', error);
			logFetchError(error, this.state.userProfile, this.state.userData, 'fetchStartCase')
		});
	}

	confirmIncompleteCaseStart = (selectedIncompleteCase, incompleteCaseList) => {
		let incompleteStartData = {}
		this.setState({fetchOutstanding: true})
		fetch(`${this.state.route}/incompletestart.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				caseguid: this.state.caseguid,
				CurrentStateId: incompleteCaseList[selectedIncompleteCase].CurrentStateId
			})
		})
		.then(response => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then((response) => {
			incompleteStartData = JSON.parse(response)
			// let currentProgress = JSON.parse(incompleteStartData.CurrentProgress)
			let previousInput

			if (incompleteStartData.PreviousInput !== "") {
				previousInput = JSON.parse(incompleteStartData.PreviousInput)
			} else {
				previousInput = []
			}

			let temp = {...this.state.simulationData}
			let timedExam = this.state.timedExam

			if (previousInput.length > 0) {
				timedExam = previousInput[0].isTimed
			}


			previousInput.forEach((inputValue, inputIndex) => {
				if (inputValue.CustomerAnswersBlank !== null) {
					temp.answerList[inputIndex] = [...inputValue.CustomerAnswersBlank]
				} else {
					inputValue.CustomerAnswerMC.forEach((value, index) => {
						//Get question index that corresponds with question id in answerList and replace value - 1
						let answerIndex = temp.ao.findIndex((answerOutputValue) => {
							if (answerOutputValue.ANSWERID === value) {
								return true
							}
						})
						temp.answerList[inputIndex][answerIndex].selected = true
					})
				}
			})

			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			temp.currentQuestion = previousInput ? previousInput.length + 1 : 1
			temp.timeRemainingInSeconds = incompleteStartData.TimeLeft

			console.log(temp)

			this.setState({
				simulationData: temp,
				appDisplay: 'simulation',
				fetchOutstanding: false,
				timedExam
			})
		})
		.catch((error) => {
			this.setState({fetchOutstanding: false})
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'confirmIncompleteCaseStart')
		})
	}

	viewCaseGrades = (id, reviewLater, diagnosis) => {
		this.setState({
			grading: null,
			appDisplay: 'grading',
			caseGrades: id,
			simulationData: null,
			reviewLater: reviewLater,
			selectedCaseDiagnosis: diagnosis,
		});
	}

	displayGrading = (grades) => {
		let reviewLater = this.state.selectedCaseReviewLater
		let diagnosis = this.state.simulationData.DIAGNOSIS
		this.setState({
			grading: null,
			appDisplay: 'grading',
			caseGrades: this.state.caseData.ID,
			simulationData: null,
			reviewLater: reviewLater,
			selectedCaseDiagnosis: diagnosis,
		})
	}

	fetchReviewLater(caseData) {
		this.setState({fetchOutstanding: true})
		fetch(`${this.state.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				caseId: caseData.id,
				marked: caseData.reviewLater
			})
		})
		.then(response => {
			this.setState({fetchOutstanding: false})
			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {
			// this.props.reviewLaterToggle(this.props.data.ID);
		})
		.catch(error => {
			this.setState({fetchOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'fetchReviewLater')
		});
	}

	exitSimulationHandler = () => {
		this.setState({appDisplay: 'caseList', caseGrades: this.state.caseData.ID})
	}

	render() {
		return (
			<div className="App">
				{this.state.appDisplay === 'caseList' ?
					<CaseList
						updateAccountDetails={this.updateAccountDetails}
						setAuthenticationError={() => this.setState({authenticationError: true})}
						userData={this.state.userData}
						userProfile={this.state.userProfile}
						fetchStartCase={this.fetchStartCase}
						timedExam={this.state.timedExam}
						timedExamToggle={() => this.setState({timedExam: !this.state.timedExam})}
						viewCaseGrades={this.viewCaseGrades}
						accountSettingsClick={() => this.setState({appDisplay: 'accountSettings'})}
						selectedCase={this.state.caseGrades}
						caseListHeaderError={this.state.caseListHeaderError}
						setCurrentScrollPosition={(scrollPosition) => this.setState({caseListScrollPosition: scrollPosition})}
						scrollPosition={this.state.caseListScrollPosition}
						confirmIncompleteCaseStart={this.confirmIncompleteCaseStart}
						route={this.state.route}
						setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
						practiceMode={this.state.practiceMode}
						togglePracticeMode={() => this.setState({practiceMode: !this.state.practiceMode})}
						caseListSort={this.state.caseListSort}
						caseListReversed={this.state.caseListReversed}
						changeCaseListSort={(newSort, isReversed) => this.setState({caseListSort: newSort, caseListReversed: isReversed})}
						showCaseTitle={this.state.showCaseTitle}
						toggleShowCaseTitle={() => this.setState({showCaseTitle: !this.state.showCaseTitle})}
						showCaseDiagnosis={this.state.showCaseDiagnosis}
						toggleShowCaseDiagnosis={() => this.setState({showCaseDiagnosis: !this.state.showCaseDiagnosis})}
					/>
				: this.state.appDisplay === 'simulation' ?
					<Simulation
						simulationData={this.state.simulationData}
						setAuthenticationError={() => this.setState({authenticationError: true})}
						route={this.state.route}
						userData={this.state.userData}
						caseData={this.state.caseData}
						selectedCaseId={this.state.selectedCaseId}
						timedExam={this.state.timedExam}
						practiceMode={this.state.practiceMode}
						exitSimulation={this.exitSimulationHandler}
						fetchOutstanding={this.state.fetchOutstanding}
						setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
						displayGrading={this.displayGrading}
						caseguid={this.state.caseguid}
						userProfile={this.state.userProfile}
					/>
				: this.state.appDisplay === 'accountSettings' ?
					<AccountSettings
						production={this.state.production}
						userProfile={this.state.userProfile}
						userData={this.state.userData}
						updateSelectedTimeZone={this.updateSelectedTimeZone}
						showLoading={this.state.showLoading}
						showLoadingHandler={this.showLoadingHandler}
						closeSettings={() => this.setState({appDisplay: 'caseList'})}
						setAuthenticationError={(newValue) => this.setState({authenticationError: newValue})}
						setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
					/>
				: this.state.appDisplay === 'grading' ?
					<Grading
						userData={this.state.userData}
						fetchOutstanding={this.state.fetchOutstanding}
						setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
						userProfile={this.state.userProfile}
						caseData={this.state.caseData}
						caseGrades={this.state.caseGrades}
						route={this.state.route}
						grading={this.state.grading}
						setAuthenticationError={() => this.setState({authenticationError: true})}
						closeGrading={() => this.setState({appDisplay: 'caseList'})}
						simulationData={this.state.simulationData}
						selectedCaseDiagnosis={this.state.selectedCaseDiagnosis}
					/>
				:
					<Login
						production={this.state.production}
						loginError={this.state.loginError}
						route={this.state.route}
						updateUserData={(userData) => this.setState({userData, appDisplay: 'caseList'})}
					/>
				}
				{(this.state.showLoading && this.state.fetchOutstanding) &&
					<div className='loadingPopupWrapper'>
						<div className='loadingPopupBackdrop' />
						<div className='loadingPopupContainer'>
							<Spinner size={40} singleColor={'#1c4670'}/>
							<p className='loadingPopupText'>Loading...</p>
						</div>
					</div>
				}
				{this.state.authenticationError &&
					<UnauthenticatedHandler />
				}
			</div>
		);
	}
}

export default App;
